function BATCH_INFO({ a, b, c, c2, d, e, f, g, h, i, j, k, data }) {
  console.log(data)
  
    const manufacturerCounts = {};

    // Count the occurrences of each manufacturer
    data?.forEach(item => {
      console.log(item)
      const manufacturer = item.original ? item.original.MANUFACTURER : item.MANUFACTURER;
      if (manufacturerCounts[manufacturer]) {
        manufacturerCounts[manufacturer]++;
      } else {
        manufacturerCounts[manufacturer] = 1;
      }
    });

    console.log(manufacturerCounts)


  return (
    <div className="py-5">
      <h1 className="text-gray-900 font-bold text-base">Batch Info</h1>
      <h1 className="text-gray-900 font-semibold text-2xl mb-1"><span  className="text-sky-500">Batch Report ID : </span>{a?.includes('undefined') && k?.includes('undefined') ? k + ' '+e : a}</h1>

      {!b ? null : (
        <div className="flex flex-row">
          <h1 className="text-sky-500 text-base font-bold">
            Customer ID : &nbsp;{" "}
          </h1>
          <h1 className="text-gray-900 font-medium text-base">{b}</h1>
        </div>
      )}

      {!c ? null : (
        <div className="flex flex-row">
          <h1 className="text-sky-500 font-bold text-base">Total IMEI Checked :&nbsp; </h1>
          <h1 className="text-gray-900 font-medium text-base">{c}</h1>
        </div>
      )}

      <div>
        {Object.keys(manufacturerCounts).map(manufacturer => (
          <div className="flex flex-row" key={manufacturer}>
          <h1 className="text-sky-500 font-bold text-base">{manufacturer} :&nbsp; </h1> 
          <h1 className="text-gray-900 font-medium text-base">{manufacturerCounts[manufacturer]}</h1>
          </div>
        ))}
      </div>

      {!c2 ? null : (
        <div className="flex flex-row">
          <h1 className="text-sky-500 font-bold text-base">IMEI2:&nbsp; </h1>
          <h1 className="text-gray-900 font-medium text-base">{c2}</h1>
        </div>
      )}

      {!d ? null : (
        <div className="flex flex-row">
          <h1 className="text-sky-500 font-bold text-base">
            Serial Number: &nbsp;{" "}
          </h1>
          <h1 className="text-gray-900 font-medium text-base">{d}</h1>
        </div>
      )}

      {!j || (b === 'APPLE' || b === 'APPLE INC') ? null : (
        <div className="flex flex-row">
          <h1 className="text-sky-500 font-bold text-base">
            Model Number: &nbsp;{" "}
          </h1>
          <h1 className="text-gray-900 font-medium text-base">{j}</h1>
        </div>
      )}

      {!f ? null : (
        <div className="flex flex-row">
          <h1 className="text-sky-500 font-bold text-base">
            Purchase Country: &nbsp;{" "}
          </h1>
          <h1 className="text-gray-900 font-medium text-base">{f}</h1>
        </div>
      )}
      {!i ? null : (
        <div className="flex flex-row">
          <h1 className="text-sky-500 font-bold text-base">
            Operating System: &nbsp;{" "}
          </h1>
          <h1 className="text-gray-900 font-medium text-base">{i}</h1>
        </div>
      )}

{!e || (b === 'APPLE INC' ||  b === 'APPLE' || b === "GOOGLE" || b === "SAMSUNG" || b === "SAMSUNG KOREA") ? null : (
        <div className="flex flex-row">
          <h1 className="text-sky-500 font-bold text-base">
            Model: &nbsp;{" "}
          </h1>
          <h1 className="text-black font-medium text-base">{e}</h1>
        </div>
      )}

      {!g ? null : (
        <div className="flex flex-row">
          <h1 className="text-sky-500 font-bold text-base">
            Part Number: &nbsp;{" "}
          </h1>
          <h1 className="text-gray-900 font-medium text-base">{g}</h1>
        </div>
      )}

      {!h ? null : (
        <div className="flex flex-row">
          <h1 className="text-sky-500 font-bold text-base">
            A Number: &nbsp;{" "}
          </h1>
          <h1 className="text-gray-900 font-medium text-base">{h}</h1>
        </div>
      )}
    </div>
  );
}

export default BATCH_INFO;
