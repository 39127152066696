import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import "./Forget.scss";

const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(null);
  const [step, setStep] = useState(1); // 1: Email, 2: Verification, 3: Reset Password
  const [code, setCode] = useState(["", "", "", ""]);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

  // Email validation regex
  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  // Handle email submission
  const handleSubmitEmail = async () => {
    if (!isValidEmail(email)) {
      setStatus({ success: false, message: "Please enter a valid email" });
      return;
    }

    setLoading(true);
    setStatus(null);
    console.log(email)
    try {
      // Call the API to send the reset password email
      const response = await axios.post("https://api.cellercertified.com/request-reset", { email });
      console.log(response)
      setStatus({ success: true, message: "Verification code sent to your email" });
      setStep(2); // Move to the verification step
    } catch (error) {
      setStatus({ success: false, message: "Failed to send verification email" });
    } finally {
      setLoading(false);
    }
  };

  // Handle verification code input change
// Handle verification code input change
const handleCodeChange = (index, value) => {
  if (value === "" || /^[0-9]$/.test(value)) {
    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);

    // Move to the next input automatically only if a digit was entered
    if (index < 3 && value !== "") {
      inputRefs[index + 1].current.focus();
    }
    // Optionally, you could move to the previous input when erasing
    else if (index > 0 && value === "") {
      inputRefs[index - 1].current.focus();
    }
  }
};


  // Handle code verification
  const handleSubmitCode = async () => {
    const verificationCode = code.join("");
    if (verificationCode.length < 4) {
      setStatus({ success: false, message: "Please enter the full verification code" });
      return;
    }

    setLoading(true);
    setStatus(null);

    try {
      // Call the API to verify the code
      const response = await axios.post("https://api.cellercertified.com/verify-code", { email, code: verificationCode });
      setStatus({ success: true, message: "Code verified, please reset your password" });
      setStep(3); // Move to the reset password step
    } catch (error) {
      setStatus({ success: false, message: "Invalid verification code" });
    } finally {
      setLoading(false);
    }
  };

  // Handle password reset
  const handleResetPassword = async () => {
    if (password !== confirmPassword) {
      setStatus({ success: false, message: "Passwords do not match" });
      return;
    }

    setLoading(true);
    setStatus(null);

    try {
      // Call the API to reset the password
      const response = await axios.post("https://api.cellercertified.com/reset-password", { email, password });
      setStatus({ success: true, message: "Password successfully reset" });

      
      // Reset the states
      setEmail("");
      setPassword("");
      setConfirmPassword("");
      setCode(["", "", "", ""]);
      setStep(1)

    } catch (error) {
      setStatus({ success: false, message: "Failed to reset password" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="forget">
      {step === 1 && (
        <div className="loginBox">
          <div className="loginFormContainer">
            <h1>Forget Password</h1>
            <br/>
            <p>Enter your email</p>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter Email"
              className="inputfield w-full"
            />
            <button
              className={`bg-sky-500 h-12 text-[1.2rem] font-bold rounded text-white  w-[100%] ${
                loading ? "cursor-not-allowed bg-gray-600" : ""
              }`}
              onClick={handleSubmitEmail}
              disabled={loading}
            >
              {loading ? "Submitting..." : "Submit"}
            </button>
            {status && (
              <p className={status.success ? "text-green-500" : "text-red-500"}>
                {status.message}
              </p>
            )}
          </div>
        </div>
      )}

      {step === 2 && (
        <div className="loginBox">
          <div className="loginFormContainer">
            <h1>Verification</h1>
            <p>Enter the verification code sent to your email</p>
            <div className="inputs flex gap-x-1">
              {code.map((value, index) => (
                <input
                  key={index}
                  ref={inputRefs[index]}
                  type="text"
                  maxLength={1}
                  value={value}
                  onChange={(e) => handleCodeChange(index, e.target.value)}
                  className="inputfield w-1/2"
                />
              ))}
            </div>
            <button
              className={`bg-sky-500 h-12 text-[1.2rem] font-bold rounded text-white  w-[100%] ${
                loading ? "cursor-not-allowed bg-gray-600" : ""
              }`}
              onClick={handleSubmitCode}
              disabled={loading}
            >
              {loading ? "Verifying..." : "Submit"}
            </button>
            {status && (
              <p className={status.success ? "text-green-500" : "text-red-500"}>
                {status.message}
              </p>
            )}
          </div>
        </div>
      )}

      {step === 3 && (
        <div className="loginBox">
          <div className="loginFormContainer">
            <h1>Reset Password</h1>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="New Password"
              className="inputfield w-full"
            />
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm Password"
              className="inputfield w-full"
            />
            <button
              className={`bg-sky-500 h-12 text-[1.2rem] font-bold rounded text-white  w-[100%] ${
                loading ? "cursor-not-allowed bg-gray-600" : ""
              }`}
              onClick={handleResetPassword}
              disabled={loading}
            >
              {loading ? "Resetting..." : "Reset Password"}
            </button>
            {status && (
              <p className={status.success ? "text-green-500" : "text-red-500"}>
                {status.message}
              </p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ForgetPassword;
