import { Check, CROSS } from "../SVGs";
function CHECKS({ afun, bfun, a, b, c, d, e, f, g, h, i,j, warranty_info,manu, k,REF, l, m }) {
  return (
    <div className="bg-gray-100 w-full ">
   
   <ITEM
    text={a === "CLEAN" ? "Not Reported lost or stolen" : "Reported Lost or Stolen – Proceed with caution and do not buy or sell this device" }
    check_color={a === "CLEAN"  ? "#00A651" : "red"}
    check={a}
    fun={() => afun()}
  />
  <div className="flex items-center w-100">
    <div className="w-1/2">
    {manu?.includes('APPLE') && <ITEM
      text={b === "OFF" ? "iCloud lock is OFF" : "iCloud lock is ON" }
      check_color={b === "OFF"  ? "#00A651" : "red"}
      check={b}
      fun={() => afun()}
    />}
    </div>
    <div className="w-1/2">
     <ITEM
      text={ l === "OFF" ? "MDM Status is OFF" : l === "ON" ? "MDM Status is ON" : "MDM Status is not Checked" }
      check_color={l === "OFF" ? "#00A651" : l === "ON" ? 'red' : 'grey'}
      check={l}
      fun={() => afun()}
    />
    </div>
  </div>
    <ITEM
    text={ j === 'CLEAN' ? "No financial issues reported – ESN Status: Clean" : j?.includes('CONTRACT') ? `${m ? m.replace(/\.$/, '') : ''} - ESN STATUS : ${j}` : a === "CLEAN" ? "No financial issues reported" : "Financial Issues were reported" }
    check_color={ j === 'CLEAN' ? '#00A651' : j?.includes('CONTRACT') ? '#ffcc00' : a === "CLEAN" ? '#00A651' : "red"}
      check={c}
      fun={() => bfun()}
    />
    <ITEM
    text={d === "UNLOCKED" || typeof d === 'undefined' ? `Carrier Lock Not found Device is UNLOCKED ` : "Carrier Locked Device" }
    check_color={d === "UNLOCKED" || typeof d === 'undefined'  ? "#00A651" : "red"}
      check={d}
      fun={() => bfun()}
    />
  <ITEM
    text={`Warranty Information Checked - ${typeof warranty_info === 'undefined'? 'Not Available': warranty_info}`}
    check_color = {(warranty_info === "OUT OF WARRANTY" || warranty_info === "EXPIRED" || warranty_info === "OOW" || ( warranty_info?.includes('EXPIRED')) || warranty_info === "WARRANTY EXPIRED" ||typeof warranty_info === 'undefined') ? "red" : warranty_info === "LIMITED WARRANTY" ? "#ffcc00 ": "#00A651"}
 check={e}
    fun={() => afun()}
  />
    <ITEM2
      text={f === "CLEAN" ? "No Device Lock Found" : "Repair History not checked" }
      check_color={f ? "#00A651" : "#808080"}
      check={f}
    />
    <ITEM2
      text={g === "CLEAN" ? "Battery Health" : "Battery Health not checked" }
      check_color={g ? "#00A651" : "#808080"}
      check={g}
    />
    <ITEM2
      text={h === "CLEAN" ? "Function Diagnostics Checked" : "Functional Diagnostics not performed" }
      check_color={h  ? "#00A651" : "#808080"}
      check={h}
      REF={REF}
    />
    <ITEM2
      text={i === "CLEAN" ? "Erase Data Checked" : "Data Erase not recorded " }
      check_color={i ? "#00A651" : "#808080"}
      check={i}
    
    />
  </div>
  );
}

function ITEM({ text, check, fun, check_color }) {
  return (
    <div
      className={`flex flex-row p-1 border-b ${check?'bg-white dark:bg-boxdark':''} border-neutral-300 items-center ${
        check ? " hover:bg-gray-300 hover:cursor-pointer" : ""
      }`}
      onClick={() => {
        fun();
      }}
    >
      <div>{<Check color={check_color} size={16} />}</div>

      <div
        className={`${!check ? "" : "font-medium"} ml-2 text-base`}
        style={{ color: check_color }}
      >
        {text}
      </div>
    </div>
  );
}


function ITEM2({ text, REF }) {
  return (
    <div ref={REF} className={`flex flex-row p-1 border-b  border-neutral-300 items-center `}>
  
       <CROSS color={'#808080'} size={16} />
      <div
        className={` ml-2 text-base`}
        style={{ color: "#808080" }}
      >
        {text}
      </div>
    </div>
  );
}

export default CHECKS;
