import { useState, useEffect } from 'react';
import {AiOutlineLeft, AiOutlineRight} from 'react-icons/ai'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import logo_3 from "../images/Logo_3.png";

// import AliceCarousel from 'react-alice-carousel';
// import "react-alice-carousel/lib/alice-carousel.css";

const CustomArrows = ({ type, onClick, isEdge }) => {
    return (
      <div
        onClick={onClick}
        className="text-2xl"
      >
        {
            type === 'PREV' ?
            <AiOutlineLeft/> : <AiOutlineRight/>
        }
      </div>
    );
  };

const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 2,
    responsive : [
        // {
        //     breakpoint: 1336,
        //     settings: {
        //       slidesToShow: 3,
        //     }
        // },
        {
            breakpoint: 1280,
            settings: {
              slidesToShow: 2,
            }
        },
        {
            breakpoint: 1240,
            settings: {
              slidesToShow: 1,
            }
        },
    ],
    prevArrow: <AiOutlineLeft type="PREV" />,
    nextArrow: <AiOutlineRight type="NEXT" />,
  };


const Products = ({ v, A_NUMBER, MODEL_DESCRIPTION, MANU, POSSIBLE_CARRIER, MODEL_NUMBER, products, filteredProducts }) => {
    // const [products, setProducts] = useState([])
    // // const [itemsToShow, setBreakPoints] = useState(3);

    // // useEffect(() => {
    // //     const calculateBreakPoints = () => {
    // //       const windowWidth = window.innerWidth;
    // //       let itemsToShow = [];
    // //       if (windowWidth  >= 800) {
    // //         itemsToShow = 3
    // //       } else if (windowWidth >= 600) {
    // //         itemsToShow = 2
    // //       } else if (windowWidth >= 0) {
    // //         itemsToShow = 1
    // //       } else {
    // //         itemsToShow = 1
    // //       }
    
    // //       setBreakPoints(itemsToShow);
    // //     };
    // //     calculateBreakPoints();
    // //     window.addEventListener('resize', calculateBreakPoints);
    // //     return () => {
    // //       window.removeEventListener('resize', calculateBreakPoints);
    // //     };
    // //   }, []);

    // const extractCapacity = (modelDescription) => {
    //     const regex = /(\d+GB)/;
    //     const match = modelDescription.match(regex);

    //     if (match) {
    //         return match[0];
    //     } else {
    //         return "RAM storage not found in the string.";
    //     }
    // }

    // const filteredProducts = products?.length > 0 ? products?.filter((product) => {

    //     if(product.aNumber && product.aNumber === A_NUMBER && product.capacity === extractCapacity(MODEL_DESCRIPTION) ){
    //         console.log("filter with aNumber and capacity")
    //         return product;
    //     }else if(product.modelName === MODEL_DESCRIPTION){
    //         console.log("filter with model")
    //         return product;
    //     }
    //     else if(product.modelNumber && product.modelNumber === MODEL_NUMBER){
    //         console.log("filter with model number")
    //         return product;
    //     }
  
    // }) : []

    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const url = 'https://api.cellercertified.com/products';
    //             const response = await fetch(url);

    //             if (!response.ok) {
    //                 throw new Error(`HTTP error! Status: ${response.status}`);
    //             }

    //             const data = await response.json();
    //             setProducts(data);
    //         } catch (error) {
    //             console.error('Error fetching data:', error);
    //         }
    //     };

    //     fetchData();
    // }, [])
  
    return (
        <div className='w-full rounded-lg my-10 p-6 px-1 sm:px-5  shadow-xl'>
        <div className="flex  items-center">
        <img src={logo_3} alt={"logo_3"} className="mr-3" />
        <h1 className="text-black font-bold text-2xl  ">BuyBack Marketplace</h1>
        </div>
        {
  filteredProducts.length === 0 ? (
    <div className='flex justify-center bg-blue-500 text-white text-xl items-center p-6 m-4 font-bold rounded-md'>
      <h1>No Products in Marketplace</h1>
    </div>
  ) : (
    <div className='flex justify-center items-center p-4 w-full md:w-full mx-auto'>
      {filteredProducts.length > 2 && <CustomArrows type="PREV" />}
      <div className='w-[100%]'>
        <Slider {...settings}>
          {filteredProducts?.map((product, index) => (
            <div className='p-2' key={index}>
              <ProductCard
                image={`https://api.cellercertified.com${product.image}`}
                modelName={product.modelName}
                modelNumber={product.modelNumber}
                aNumber={product.aNumber}
                MVNEW={product.MVNew}
                MVUsed={product.MVUsed}
                MVParts={product.MVParts}
                BBPoor={product.BBPoor}
                condition={product.condition}
                capacity={product.capacity}
                product={product}
                Color={product.Color}
                carrier={product.carrier}
              />
            </div>
          ))}
        </Slider>
      </div>
      {filteredProducts.length > 2 && <CustomArrows type="NEXT" />}
    </div>
  )
}

        </div>
    )
}


const ProductCard = ({ image, modelName, MVNEW,MVUsed,BBFair,MVParts, condition, capacity, modelNumber, aNumber, product, Color, carrier }) => {
  console.log(product)

    return (
        <div className="border border-gray-200 bg-gray-50 rounded-md w-full flex flex-col justify-center items-center">
            <div className='bg-white w-full'>
                {image && image.includes(undefined) ? <div className='h-32 max-w-32 py-10 text-2xl mx-auto text-center'>Image Unavailable</div> : <img className='h-32 max-w-32 mx-auto bg-white' src={image} alt={modelName} />}
            </div>
            <div className='text-center' >
                <h1 className='text-black font-bold'>{modelName}</h1>
            </div>
            <div className="flex text-black p-0">
                <div className="w-1/2 pr-2">
                <div>Series</div>
                <div className="">Capacity</div>
                <div>Carrier</div>
                {modelNumber === "N/A" ? <div>ANumber</div> : !modelNumber ? <div>ANumber</div> : aNumber ? <div>ANumber</div> : <div>ModelNumber</div>}
                    <div className="">Condition</div>
                   
                    <div>Color</div>
                    <div className="text-green-500">Price</div>
                </div>
                <div className="w-1/2 pl-2">
                <div>{
                modelName.match(/iphone\s?\d+(?:\spro\smax|\spro|\smax)?/i) 
                ? modelName.match(/iphone\s?\d+(?:\spro\smax|\spro|\smax)?/i)[0] 
                : "Unknown Model"
              }</div>
              <div>{capacity}</div>
                <div>{carrier}</div>
                    <div className='whitespace-nowrap'>{modelNumber === "N/A" ? aNumber : !modelNumber ? aNumber : aNumber ? aNumber : modelNumber ? modelNumber : modelNumber !== "N/A" ? modelNumber : ""}</div>
                    <div>{condition ? condition : 'Unavailable'}</div>
                   
                    <div>{Color ? Color : 'n/a'}</div>
                    {
                        condition === "Excellent" ? 
                         <div className="text-green-500">${MVNEW}</div> : 
                         condition === "New" ? 
                         <div className="text-green-500">${MVNEW}</div> : 
                         condition === "Fair" ?
                         <div className="text-green-500">${MVUsed}</div> : 
                         condition === "Poor" ? 
                         <div className="text-green-500">${MVParts}</div> : "Unavailable"
                    }
                   
                </div>
            </div>
            {product.buyNowLink ? <a href={product.buyNowLink} target="_blank" className="flex justify-center items-center bg-green-500 rounded-b-md text-white text-lg font-bold mt-2 w-full py-2">Buy</a> : 
            <a href='https://www.buybackworldcertified.com/' target="_blank" className="flex justify-center items-center bg-green-500 rounded-b-md text-white text-lg font-bold mt-2 w-full py-2">Buy</a>}
        </div>
    );
};


export default Products