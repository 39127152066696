import { Link } from "react-router-dom";
import "./Register.scss";
import tick from "../../images/tick.png";
import mobile from "../../images/mobile.png";
import { useState } from "react";

const Register = () => {

  const [ First_Name,setFirst_Name] = useState('')
  const [ Last_Name,setLast_Name] = useState('')
  const [ Email,setEmail] = useState('')
  const [Password,setPassword] = useState('')
  const [Password2,setPassword2] = useState('')
  const [Phone,setPhone] = useState('')
  const [Address,setAddress] = useState('')
  const [Country,setCountry] = useState('')
  const [City,setCity] = useState('')
  const [zipcode,setZipcode] = useState('')

  const [Loading,setLoading] = useState(false)
  const [Pwd_Matches,setPwd_Matches] = useState(true)
  const [All_Fields,setAll_Fields] = useState(true)
  const [validEmail,setvalidEmail] = useState(true) 
  const [User_Created,setUser_Created] = useState(false)
  const [userExsist,setuserExsist] = useState(false)


  function isValidEmail(email) {
    var pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  }
  function reset_errors(){

    Pwd_Matches(true)
    All_Fields(true)

  }
  function check_all_fields(){
    if(
      First_Name ===  ''||
      Last_Name === '' ||
      Password  === '' ||
      Password2 === '' ||
      Email  === '' ||
      Phone === '' ||
      Address === '' ||
      City === '' ||
      Country === '' ||
      zipcode === '' 
      )
      {
        
        setAll_Fields(false)
        return false;
      }
      setAll_Fields(true)
      return true
      
  }
  function handleFNAMEChange(event) {
    setFirst_Name(event.target.value);
  }
  function handleEmailChange(event) {
    setEmail(event.target.value);
  }
  function handleLNAMEChange(event) {
    setLast_Name(event.target.value);
  }
  function handlePhoneChange(event) {
    setPhone(event.target.value);
  }
  function handlePWDChange(event) {
    setPassword(event.target.value);
  }
  function handlePWD2Change(event) {
    setPassword2(event.target.value);
  }
function Address_Change(event) {setAddress(event.target.value);}
function Country_Chnage(event) {setCountry(event.target.value);}
function City_Change(event) {setCity(event.target.value);}
function Zipcode_Change(event) {setZipcode(event.target.value);}

function handleRegister(event) {
  setLoading(true)
  event.preventDefault(); 
  if(User_Created){return;}
  if(!check_all_fields()){setAll_Fields(false);setLoading(false);return;} else {setAll_Fields(true)}
  if(!isValidEmail(Email)){setvalidEmail(false);setLoading(false);return;} else{setEmail(true)}
  if(Password !== Password2){ setPwd_Matches(false); setLoading(false); return;}else{setPwd_Matches(true)}

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  
  var urlencoded = new URLSearchParams();
  urlencoded.append("Email", Email.toLowerCase());
  urlencoded.append("Password", Password);
  urlencoded.append("Type", "client");
  urlencoded.append("First_Name", First_Name);
  urlencoded.append("Last_Name", Last_Name);
  urlencoded.append("Phone", Phone);
  urlencoded.append("Address",Address );
  urlencoded.append("Country", Country);
  urlencoded.append("City",City );
  urlencoded.append("Zipcode",zipcode );
  
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: urlencoded,
    redirect: 'follow'
  };
  //api.cellercertified.com
  fetch("https://api.cellercertified.com/register", requestOptions)
    .then(response => response.text())
    .then(result =>{ 
      var jsonObject = JSON.parse(result);
      var res = jsonObject.response;
      if(res === 'user exists'){
        console.log("user already regisrered")
        setuserExsist(true)
        setLoading(false)
        return;
      }
      else{
        setuserExsist(false)
      }
      if(res ==="user created "){
        setUser_Created(true);
        return;
      }
    })
    .catch(error => {})
    .finally()

}




  return (
    <div className="register">
      <div className="card">

        <div className="left">
          <h1>Benefits of an Account</h1>
          <div className="desc">
            <div className="each">
              <img src={tick} alt="" />
              <span>First Check FREE</span>
            </div>
            <div className="each">
              <img src={tick} alt="" />
              <span>Savings up to 75% on individual reports</span>
            </div>
            <div className="each">
              <img src={tick} alt="" />
              <span>
                Keep a detailed record of all IMEI checks in one secure location
              </span>
            </div>
            <div className="each">
              <img src={tick} alt="" />
              <span>Easy to use cloud-based interface</span>
            </div>
            <div className="each">
              <img src={tick} alt="" />
              <span>
                Increase re-sale value of your devices with a CellerCertified
                Report
              </span>
            </div>
            <div className="each">
              <img src={tick} alt="" />
              <span>Barcodes for your devices</span>
            </div>
            <div className="each">
              <img src={tick} alt="" />
              <span>Multiple Account Users</span>
            </div>
            <div className="each">
              <img src={tick} alt="" />
              <span>Bulk Check Multiple IMEIs at once</span>
            </div>

            <div className="each">
              <img src={tick} alt="" />
              <span>API Access & More</span>
            </div>
          </div>
          <img src={mobile} alt="" className="h-full" />
        </div>

        <div className="right">
          <h1>Sign Up</h1>
          <p>
            Benefit from bulk discounts, status change alerts and much more with
            a CellerCertified account
          </p>
          <form onSubmit={handleRegister}>
            <input className="h-11"  type="text" name="name" placeholder="First Name" onChange={handleFNAMEChange}/>
            <input className="h-11" type="text" placeholder="Last Name" name="username" onChange={handleLNAMEChange}/>{" "}
            <input className="h-11" type="number" placeholder="Phone Number" name="" onChange={handlePhoneChange}/>
            <input className="h-11" type="email" placeholder="Your Email Address" name="email" onChange={handleEmailChange}/>
            <input className="h-11"
              type="password"
              placeholder="Your Password"
              name="password"
              onChange={handlePWDChange}
            />
            <input className="h-11"
              type="password"
              placeholder="Confirm Password"
              name="password"
              onChange={handlePWD2Change}
            />

            <input className="h-11"  placeholder="Address" name="email" onChange={Address_Change}/>
            <input className="h-11" type="text" placeholder="Country" name="" onChange={Country_Chnage}/>
            <input className="h-11" type="text" placeholder="City" name="" onChange={City_Change}/>
            <input className="h-11" type="number" placeholder="zipcode" name="" onChange={Zipcode_Change}/>
            <button 
            type="submit"
            className={`mt-3 rounded text-white  font-medium  w-[100%] mb-3  h-12  text-2xl 
            ${Loading? "bg-gray-500 cursor-not-allowed ":"bg-sky-500"}`}

            >
              Register
            </button>
            {!All_Fields && <span className="text-red-500">Please Fill All the Fields !</span>}
            {!validEmail && <span className="text-red-500">Unvalid Email !</span>}
            {!Pwd_Matches && <span className="text-red-500">Password Do not Match !</span>}
            {userExsist && <span className="text-red-500">User Already Exsist !</span>}
            {User_Created && <span className="text-green-500">User Created !</span>}
            

           <div
              style={{
                display: "flex",
              }}
            >
              Already have an account? &nbsp;
              <Link to="/login" style={{ color: "#00AEEF" }}>
                Sign In Now
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Register;
